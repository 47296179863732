/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
html, body { 
    height: 100%;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color:#00658d;
    font-family: system-ui !important;
}

.mat-form-field-infix{
    position: initial !important;
    width: fit-content !important;
    
}

.mat-form-field-label {
    all: unset !important;
    position: absolute !important;
    left: 0 !important;
    top: 20px !important;
    font-size: 13px !important;
    color: #757575 !important;
  }
  

body { margin: 0; font-family: system-ui; background-color:- #E2E8F0 !important;}
